<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="积分详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div>
      <!-- <div class="top"></div> -->
      <!-- <div style="height: 25px"></div>
      <div class="head">
        积分中心
        <img
          class="left"
          @click="$router.back(-1)"
          src="../../../assets/ShopImg/left_w.png"
          alt=""
        />
      </div> -->

      <div class="exchange">
        <!-- <img src="../../../assets/images/bg_pointsmall.png" alt="" srcset="" /> -->
        <div class="exchange_1">
          <p>当前积分</p>
          <p>{{ user.Point ? user.Point : 0 }}</p>
        </div>

        <div class="exchange_2" @click="goPointSmall">
          <span>兑换</span>
        </div>
      </div>
    </div>

    <van-tabs
      v-model="active"
      color="#000"
      title-active-color="#000"
      title-inactive-color="#666666"
      @click="changeTabStatus"
    >
      <van-tab title="积分明细">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="postQueryPointList"
          v-if="pointList.length > 0"
        >
          <div class="tabs_out" v-for="(item, i) in pointList" :key="i">
            <div class="tabs_div">
              <div class="one">
                <p>{{ item.SourceStr }}</p>
                <p>{{ item.CreateTime }}</p>
              </div>
              <div class="two">
                {{ item.Category == 1 ? "+" : "-" }}{{ item.Point }}
              </div>
            </div>
          </div>
        </van-list>
        <div v-else>
          <nocontent name="暂无积分明细"></nocontent>
        </div>
      </van-tab>
      <van-tab title="兑换记录">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="postQueryPointList"
          v-if="pointList.length > 0"
        >
          <div class="tabs_out" v-for="(item, i) in pointList" :key="i">
            <div class="tabs_div">
              <div class="one">
                <p>{{ item.SourceStr }}</p>
                <p>{{ item.CreateTime }}</p>
              </div>
              <div class="two_2">
                {{ item.Category == 1 ? "+" : "-" }}{{ item.Point }}
              </div>
            </div>
          </div>
        </van-list>
        <div v-else>
          <nocontent name="暂无退兑换记录"></nocontent>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { queryPointList } from "@/api/shop";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    nocontent,
  },
  data() {
    return {
      active: 0,
      pointList: [],
      isLoad: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      user: {},
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "积分详情";
      this.isWxApplets = false;
    }
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.user.Id = 0;
    }
    console.log(this.user);
    this.postQueryPointList();
    // window.addEventListener( 'scroll', this.listenScroll);
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    goPointSmall() {
      this.$router.push({
        path: "/shop/pointsmall/index",
        query: {},
      });
    },
    changeTabStatus(name, title) {
      this.active = name;
      this.pageIndex = 1;
      this.pointList = [];
      this.postQueryPointList();
    },
    // 列表数据
    async postQueryPointList() {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.Category = this.active == 1 ? 0 : 1;
      parm.UserId = this.user.Id;
      const res = await queryPointList(parm);
      this.pointList = this.pointList.concat(res.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.pointList.length >= res.dataCount) {
        this.finished = true;
      }
      // this.isLoad = false;
      // if (res.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    //滚动
    listenScroll() {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          that.postQeryShopGoodsList();
        }
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 210px;
  background: linear-gradient(132deg, #ff2a00 0%, #ff8c28 100%);
  position: absolute;
  top: 0;
  z-index: -10;
}

.head {
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative;

  .left {
    width: 20px;
    height: 23px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.exchange {
  display: flex;
  align-items: center;
  text-align: center;
  // margin-top: 20px;
  padding: 35px 0;
  position: relative;
  background: #efeded;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  div {
    width: 100%;
  }

  .exchange_1 {
    p:first-child {
      font-size: 14px;
      color: #000000;
    }

    p:last-child {
      font-size: 30px;
      font-weight: 700;
      color: #000000;
    }
  }

  .exchange_2 span {
    color: #fff;
    background: #000;
    padding: 8px 22px;
    border-radius: 20px;
    font-weight: 500;
  }
}

.van-tab__text {
  font-weight: bold;
}

.tabs_out {
  margin-top: 5px;

  .tabs_div {
    height: 77px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .one p:first-child {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
  }

  .one p:last-child {
    color: #c5c3c3;
    font-size: 12px;
  }

  .two {
    color: #fe3030;
    font-size: 16px;
    font-weight: bold;
  }
  .two_2 {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
